.page {
    height: 100vh;
    background-color: white;
}

.content {
    overflow-y: auto;
    height: 82vh;
    padding: 20px;
}


.menuIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.inputContainer {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid #F1F1F1;
}

.search {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid #F1F1F1;
}

.searchInput {
    width: 90%;
    height: 35px;
    border-radius: 50px;
    margin-left: 15px;
    margin-left: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 10px;
    outline: none !important;
    border: 1px #808080 solid;
    background-color: #F5F5F5;
}

.searchButton {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #338447;
    border-radius: 50px;
    padding: 8px;
    margin-right: 15px;
}

.userContainer {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.history {
    border-top: 1px solid #F1F1F1;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

.history:hover {
background-color: #F1F1F1;
}

.historyTitle {
    font-size: 15;
    color: #222;
    font-weight: 600;
    margin: 2px;
}

.historyContent {
    font-size: 12;
    color: #808080;
    margin: 0;
    padding: 0;
}