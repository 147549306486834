.container {
    height: 92vh;
    background-color: 'white';
    overflow: hidden;
}

.content {
    height: 92vh;
    background-color: 'white';
    overflow-y: auto;
    overflow-x: hidden;
}