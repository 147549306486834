@media only screen and (max-width: 1024px) {
    .loginDescrition {
        display: none;
    }

    .downloadTitle {
        display: none;
    }

    .downloadMobileTitle {
        display: block;
    }
}

@media only screen and (min-width: 1024px) {
    .loginContainer {
        padding-left: 33%;
        padding-right: 33%;
    }

    .downloadTitle {
        display: block;
    }

    .downloadMobileTitle {
        display: none;
    }
}

.container {
    width: 100vw;
    height: 100vh;
    background-color: white;
    overflow-x: hidden;
    overflow-y: hidden;
}

.downloadContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom: 0.5px #c0c0c0 solid;
}

.downloadTitle {
    font-size: 14px;
    color: #222;
    font-weight: bold;
    max-width: 30%
}

.downloadMobileTitle {
    font-size: 14px;
    color: #222;
    font-weight: bold;
    max-width: 30%
}


.loginContainer {
    height: 92vh;
    justify-content: center;
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 100px;
}

.loginLogo {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.loginTitle {
    color: #222;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
}

.loginDescrition {
    text-align: justify;
    font-weight: 400;
    font-size: 14px;
    color: #222;
    margin-bottom: 20px
}


.errorMessage {
    color: red;
    font-size: 13px;
    text-align: center;
    margin-bottom: 5%;
}

.form {
    padding-left: 15%;
    padding-right: 15%;
}

.forgetPasswordText {
    font-size: 13px;
    color: #01195f;
    text-align: center;
}

.connectWith {
    font-size: 13px;
    color: gray;
    text-align: center;
    margin-top: 10px;
}

.selectLanguageInput {
    font-size: 13px;
    margin-top: 6%;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.termsContainer {
    margin-bottom: 5%;
}

.termsText {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #808080;
    margin-left: 30px;
    margin-right: 30px;
}

.copyrigthText {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #808080;
    margin-bottom: 0px;
}

.footerLogo {
    display: flex;
   justify-content: center;
}
