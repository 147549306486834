.opinion {
    width: 100%;
    border-bottom: 8px #f8f6f6 solid;
    background-color: white;
    padding-bottom: 20px;
    overflow-x: hidden;
}

.opinion .user-container {
    display: flex;
    align-items: center;
    padding: 20px;
}

.opinion .user-container .name-container {
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
}

.opinion .user-container .picture-profile {
    width: 60px;
    height: 60px;
    border: 2px #1880a1 solid;
    border-radius: 30px;
}

.opinion .user-container .name {
    font-size: 18px;
    margin: 0px;
}

.opinion .user-container .username {
    font-size: 14px;
    font-weight: 600;
    color: #808080;
    margin: 0px;
}


.opinion .sond-picture {
    width: 100%;
}

.opinion .sond-caption {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    cursor: pointer;
}

.opinion .timestamp-container {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.opinion .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 10px;
    border-top: 1px #f1f1f1 solid;
}


.opinion .comment-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-top: 1px #f1f1f1 solid;
}

.opinion .comment-container input {
    width: 83%;
    border-radius: 30px;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f0f0f0;
    outline: none;
}