@media only screen and (max-width: 1024px) {
    .contentContainer {
        height: 76vh;
    }

    .loadingContainer {
        height: 66vh;   
        padding-top: 50%;
    }
}


@media only screen and (min-width: 1024px) {
    .contentContainer {
        height: 84vh;
    }

    .loadingContainer {
         height: 84vh;
         padding-top: 30%;
    }
}

.page {
    flex: 1;
    overflow: hidden;
    background-color: white;
}

.contentContainer::-webkit-scrollbar {
    width: 15px;
}

.contentContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.contentContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.contentContainer::-webkit-scrollbar-thumb:hover {
    background: #808080;
}


.tab {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #f1f1f1;
}

.tablabel {
    padding: 20%;
    color: #808080;
    font-weight: 600;
    cursor: pointer;
    margin: 0px;
}


.header {
    height: 53px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
}

.headerTitle {
    font-size: 22px;
    font-weight: bold;
    color: #222;
    margin: 0;
}

.headerButton {
    border-radius: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 500;
    color: white;
    background-color: var(--color-primary);
    /* border: 1px solid var(--color-primary); */
}

.contentContainer {
    overflow-y: scroll !important;
    padding-bottom: 30px;
    background-color: white;
}

.loadingContainer {
    background-color: white;
}

.loadingText {
    text-align: center;
    color: #969696;
    font-weight: 500;
    margin-top: 10px;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
}