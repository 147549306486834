.comment-input-component {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    /* border-top: 1px #f1f1f1 solid; */
}

.comment-input-component input {
    width: 83%;
    border-radius: 30px;
    border: none;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f1f1f1;
    outline: none;
}

.emoji-picker {
    position:relative;
    display: flex;
    justify-content: center;
}
