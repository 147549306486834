.opinion-details{
    background-color: white;
    width: 100%;
    height: 100vh;
    overflow: hidden,
}

.opinion-details .content-box {
    height: 92vh;
    padding-bottom: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}


.opinion-details .content-box::-webkit-scrollbar {
    width: 15px;
}

.opinion-details .content-box::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.opinion-details .content-box::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.opinion-details .content-box::-webkit-scrollbar-thumb:hover {
    background: #808080;
}