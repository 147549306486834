@media only screen and (max-width: 1024px) {

    ::-webkit-scrollbar {
        display: none;
    }

    .outlet {
        width: 100%;
        height: 50vh !important;
    }

    .sidebar {
        display: none;
    }

    .endbar {
        display: none;
    }

    .navbar {
        display: block;
    }

}

@media only screen and (min-width: 1024px) {

    .outlet {
        width: 50%;
        height: 100vh;
    }

    .sidebar {
        display: block;
        width: 25%;
        height: 100vh;
    }

    .endbar {
        display: block;
        width: 25%;
        height: 100vh;
    }
    .navbar {
        display: none;
    }
}



.appLayout {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.outlet {
    padding-bottom: 200px;
    background-color: rgb(252, 249, 249);
    border-left: 1px #f1f1f1 solid;
    border-right: 1px #f1f1f1 solid;
}



button {
    background-color: white;
    color: #404040;
    border: none;
    font-size: 16px !important;
    margin-left: 10px;
}