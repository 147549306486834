.page {
    height: 100vh;
    background-color: white;
    overflow: hidden;
}

.contentContainer {
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: scroll;
    height: 93vh;
}

.contentContainer::-webkit-scrollbar {
    width: 10px;
}

.contentContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.contentContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.contentContainer::-webkit-scrollbar-thumb:hover {
    background: #808080;
}

.sendButton {
    width: 98px;
    font-weight: 500;
    color: white;
    background-color: var(--color-primary);
    border-radius: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
}

.attachButton {
    margin-right: 5px;
    cursor: pointer;
}

.recipientInputContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    padding-top: 5px;
    padding-bottom: 3px;
}


.recipientLabel {
    width: 50px;
}

.recipientInput {
    min-width: 10%;
    font-size: 14px;
    min-height: 40px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px;
}

.recipientInput:focus {
    outline: none !important;
    border: 1px solid white;
    box-shadow: 0 0 2.3px white;
}

.subjectInput {
    width: 100%;
    font-size: 14px;
    height: 37px;
    border: 1px solid white;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.subjectInput:disabled { 
background-color: white;
}

.subjectInput:focus {
    outline: none !important;
    border: 1px solid white;
    box-shadow: 0 0 2.3px white;
}

.messageInputContainer {
    display: block;
}

.messageInputContainerHide {
    display: none;
}

.messageInput {
    width: 100%;
    height: 100%;
    font-size: 14px;
    border: 1px solid white;
    border-radius: 5px;
    padding-top: 20px;
    margin-bottom: 20px;
}

.messageInput:focus {
    outline: none !important;
    border: 1px solid white;
    box-shadow: 0 0 2.3px white;
}

.suggestionContainer {
    display: block;
}

.suggestionContainerHide {
    display: none;
}

.suggestionItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
}

.suggestionName {
    font-weight: 600;
    margin: 0;
}

.suggestionUsename {
    margin: 0;
    color: #969696;
}

.recipientContainer {
    display: flex;
    flex-wrap: wrap;
}

.recipientItem {
    height: 36px;
    display: flex;
    align-items: center;
    border: 1px solid #c0c0c0;
    border-radius: 30px;
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.recipientName {
    margin: 0;
}

.recipientButton {
    margin-left: 3px;
    border-radius: 30px;
}