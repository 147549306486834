@media only screen and (max-width: 1024px) {
    .page {
        height: 92vh;
    }
}


@media only screen and (min-width: 1024px) {
    .page {
        height: 100vh;
    }
}

.page {
    overflow-y: hidden;
    background-color: white;
}

.tab {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
}

.tabLabel {
    width: 50%;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    color: #969696;
    border-bottom: 2px solid #F1F1F1;
    overflow-x: hidden;
    padding-top: 12px;
    padding-bottom: 12px;
}

.tabLabelActive {
    width: 50%;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    color: var(--color-primary);
    border-bottom: 3px solid var(--color-primary);
    overflow-x: hidden;
    padding-top: 12px;
    padding-bottom: 12px;
}

.tabView {
    display: none;
    height: 90vh;
}

.tabViewActive {
    height: 90vh;
    display: block;
}