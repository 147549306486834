.container {
    display: flex;
    padding: 15px;
    border-bottom: 0.5px solid #f1f1f1;
    cursor: pointer;
}

.container:hover {
    background-color: #F5F5F5;
}

.iconContainer {
    width: 10%;
}

.icon {
    width: 38px;
    height: 38px;
    margin-right: 10px; 
}

.content {
    width: 90%;
}

.title {
    font-size: 16px;
    font-weight: bold;
    margin: 0px
}

.description {
    font-size: 14px;
    color: #808080;
    margin: 0px
}

.date {
    font-weight: 500;
    font-size: 13px;
    color: #414141;
    font-weight: 400;
    margin: 0px
}