.page {
    height: 100vh;
    background-color: white;
}

.content {
    overflow-y: auto;
}

.option {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.option:hover {
    background-color: #F5F5F5;
}

.optionIcon {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 30px;
    height: 30px;
    color: white;
}

.optionLabel {
    font-weight: 600;
}

.optionDesc {
    font-weight: 500;
    margin: 0;
    padding: 0;
}