.container {
    height: 95vh;
    background-color: white;
}

.balanceContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px;
    padding: 20px;
    box-shadow: 5px;
    border: 1px solid #C0C0C0;
    border-radius: 5px;
}


.pressable {
    display: flex;
    cursor: pointer;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
}

.pressable:hover {
   background-color: #F1F1F1;
}

.iconPressable {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.titlePressable {
    font-weight: 600;
   margin-bottom: 0px;
}

.descPressable {
    font-size: 15px;
    color: #808080;
}