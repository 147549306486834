.page {
    background-color: white;
}

.contentContainer {
    height: 92vh;
    padding: 20px;
    overflow-y: auto;
    padding-bottom: 100px;
}


.contentContainer::-webkit-scrollbar {
    width: 15px;
}

.contentContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.contentContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.contentContainer::-webkit-scrollbar-thumb:hover {
    background: #808080;
}

.senderText {
    font-size: 12px;
    color: #808080;
    margin: 0px;
}

.receiverText {
    font-size: 12px;
    color: #808080;
    margin-top: 3px;
}

.ccContainer {
    display: flex;
    margin-top: 5px;
}

.ccLabel {
    font-size: 14px;
    /* margin: 0px; */
}

.recipientCcContainer {
    display: flex;
    flex-wrap: wrap;
}

.ccNameText {
    color: #808080;
    margin-top: 3px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 12px;
}

.replyContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #f1f1f1;
    margin-top: 60px;
    padding-top: 30px;
}

.replyButton {
    width: 150px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 600;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 20px;
    text-decoration: none;
}

.replyButton:hover {
    color: var(--color-primary);
}

.forwardButton {
    width: 150px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 600;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 15px;
    text-decoration: none;
}

.forwardButton:hover {
    color: var(--color-primary);
}