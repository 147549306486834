.TextInput {
    font-size: 14px;
    height: 37px;
    border: 1px gray solid;
    border-radius: 5px;
    padding: 10px;
}

.TextInput:focus {
    outline: none !important;
    border:1px solid var(--color-primary);
    box-shadow: 0 0 2.3px var(--color-primary);
}