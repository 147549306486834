.screen {
    height: 95vh;
    background-color: white;
}

.container {
    height: 95vh;
    padding-bottom: 20px;
    overflow-y: scroll;
}

.searchContainer {
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
}

.containerButton {
    width: 100%;
    display: flex;
    justify-content: center;
}

.inputBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.optionButton {
    display: flex;
    justify-content: center;
    height: 36px;
    align-items: center;
    background-color: var(--color-primary);
    border-radius: 50px;
    padding: 8px;
}