.container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
}

.contentContainer {
    width: 120px;
    height:140px;
    border: 1px solid #c0c0c0;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    width: 65px;
    height: 80px;
}

.name {
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    color: #222;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}