.container {
    border-top: 1px solid #f1f1f1;
    padding-top: 10px;
    min-height: 100px;
}

.senderContainer {
    display: flex;
    align-items: center;
}

.senderName {
    font-weight: 600;
    font-size: 14px;
    margin: 0px;
}

.senderEmail {
    font-size: 12px;
    color: #808080;
    margin: 0px;
}

.date {
    font-size: 12px;
    color: #808080;
    margin-top: 20px;
}

.recipientLabel {
    width: 50px;
}

.message {
    margin-top: 20px;
    font-size: 16px;
}