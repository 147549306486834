@media only screen and (max-width: 1024px) {
    .loginform {
        width: 100%;
        overflow-y: auto;
        height: 100vh;
    }

    .loginform .description {
        display: none;
    }
}

@media only screen and (min-width: 1024px) {
    .loginform {
        width: 100%;
        padding-left: 20%;
        padding-right: 20%;
        overflow-y: auto;
    }

    .loginform .description {
        display: block;
    }

    .loginform .form {
        margin-left: 20%;
        margin-right: 20%;
    }
}


.loginform {
    display: wrap;
    justify-content: center;
    position: fixed;
    overflow-y: auto;
    height: 100vh;
}


.loginform::-webkit-scrollbar {
    width: 14px;
}


.loginform::-webkit-scrollbar-thumb:hover {
    background: #888;
}


.loginform::-webkit-scrollbar-thumb:active {
    background: #555;
}

.loginform::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.loginform::-webkit-scrollbar-thumb {
    background: #888;
}


.loginform .container {
    flex-wrap: wrap;
    width: 65%;
    text-align: center;
}

.loginform .container .logo-myopinion {
    width: 120px;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-right: 10px;
}

.loginform .container .error-message {
    color: red;
    font-size: 13px;
    text-align: center;
    margin-bottom: 5%;
}

.loginform .container .forget-password-text {
    font-size: 13px;
    color: #01195f;
}

.loginform .container .connect-with {
    font-size: 13px;
    color: gray;
    text-align: center;
    margin-top: 10px;
}

.loginform .container .social {
    margin-left: -10px;
    width: 99%;
}

.loginform .container a {
    color: #222;
}

.loginform .container .select-language {
    font-size: 13px;
    margin-top: 6%;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.loginform .container .terms-conditions {
    margin-bottom: 5%;
}


.loginform .container .terms-conditions .policy {
    font-size: 13px;
    font-weight: 500;
    color: #808080;
}


.loginform .container .copyrigth {
    font-size: 14px;
    font-weight: 500;
    color: #808080;
    padding-bottom: 3%;
}