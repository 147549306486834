.container {
    height: 100vh;
    background-color: white;
}

.content {
    height: 92vh;
    padding-top: 20px;
    padding-bottom: 100px;
    overflow-y: scroll;
}

.loadingContent {
    display: flex;
    justify-content: center;
    padding-top: 30vh;
}

.containerAccount {
    margin-bottom: 10px;
}

.titleAccount {
    color: var(--color-primary);
    font-size: 14px;
    font-weight: bold;
}

.addAccountButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 55px;
    margin-top: 15px;
    margin-left: 0px;
    border: 1px var(--color-primary) solid;
    border-radius: 5px;
}


.containerOption {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    cursor: pointer;
}


.textContainerOption {
    margin-left: 20px;
    margin-right: 20px;
}

.textOption {
    font-size: 15px;
    color: #414141;
    font-weight: 600;
    margin: 0px;
}

.subtextOption {
    font-size: 14px;
    color: #808080;
    font-weight: 500;
    margin: 0px;

}

.iconOption {
    color: #414141;
    width: 25px;
    height: 25px;
}

.ownerContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #c0c0c0;
    cursor: pointer;
}

.ownerNameBox {
    margin-left: 15px;
}

.ownerName {
    font-weight: 600;
    margin: 0px;
}

.ownerUsername {
    font-size: 14px;
    color: #808080;
    margin: 0px;
}