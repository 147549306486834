@media only screen and (max-width: 1024px) {
    .forget-password .card-form {
        width: '40%';
    }
}

@media only screen and (min-width: 1024px) {
    .forget-password .card-form {
        width: '30%';
    }
}


.forget-password {
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: white;
}


.forget-password .card-form {
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 5%;
}

.forget-password .card-form .title {
    color: #222;
    text-align: center;
    font-weight: 'bold'
}

.forget-password .card-form .subtitle {
    color: #808080;
    text-align: center;
    font-size: 16px;
}

.forget-password .card-form .error-message {
    color: red;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-bottom: 5%;
}


.forget-password .card-form form {
    display: flex;
    flex-flow: column wrap;
    text-align: center;
}

.forget-password .card-form .error-message {
    color: red;
    font-size: 13px;
    text-align: center;
    margin-bottom: 5%;
}