.screen {
    height: 100vh;
    background-color: white;
}

.pressable {
    display: flex;
    cursor: pointer;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.pressable:hover {
   background-color: #F1F1F1;
}

.iconPressable {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.titlePressable {
    font-weight: 600;
   margin-bottom: 0px;
}

.descPressable {
    font-size: 15px;
    color: #808080;
}