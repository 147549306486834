.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: white;
    border-bottom: 1px #f1f1f1 solid;
}

.backButton {
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
}

.backButton:hover {
    background-color: #f1f1f1;
    border-radius: 20px;
}

.titleContainer {
    width: 100%;
    margin-right: 12%;
}

.title {
    text-align: center;
    font-weight: 600;
    color: #222;
}

.menuContainer {
    width: '10%';
}

