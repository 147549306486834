.screen {
    height: 100vh;
    padding-bottom: 5vh;
    background-color: white;
}

.container {
    height: 100vh;
    padding-top: 20px;
    overflow-y: auto;
}

.form {
    overflow-y: auto;
    background-color: white;
    height: 100vh;
    padding-top: 15px;
    padding-bottom: 100px;
    padding-left: 10%;
    padding-right: 10%;
}

.textError {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    color: red;
    text-align: center;
}


.titleInput {
    color: #01195f;
    font-size: 15px;
    font-weight: bold;
}

.inputContainer {
    margin-bottom: 20px;
}

.inputCountryContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}


.button {
    width: 50%;
    font-size: 15px;
    text-align: center;
    background-color: #25447e;
    margin-left: auto;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 150px;
}

.button:hover {
    color: white;
    background-color: #3d5f92;
    margin-left: auto;
}

.buttonBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.boxCategories {
    display: flex;
    justify-content: center;
    width: 100%;
}

