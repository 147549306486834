.notifications {
    height: 91vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.notifications::-webkit-scrollbar {
    width: 15px;
}

.notifications::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.notifications::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.notifications::-webkit-scrollbar-thumb:hover {
    background: #808080;
}