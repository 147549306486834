@media only screen and (max-width: 1024px) {
    .home .header {
        display: none;
    }
}


@media only screen and (min-width: 1024px) {
    .home .header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        border-bottom: 1px #f1f1f1 solid;
    }
}

.home {
    display: flexbox;
    width: 100%;
    height: 91vh;
    margin-top: -15px;
    background-color: white;
}

.home .header .title {
    color: #414141;
    font-weight: bold;
    text-align: center;
}