.sidebar {
    width: 100%;
    height: 91vh;
    background-color: white;
    overflow-y: hidden;
}


.sidebar .logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-bottom: 1px #f1f1f1 solid;
}

.sidebar .logo-box .logo {
    width: 200px;
    margin-bottom: -3px;
}

.sidebar .menu {
    margin-top: 8%;
    left: 15%;
}

.sidebar .profile-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 10%;
    padding-right: 20%;
    padding-top: 3%;
    padding-bottom: 3%;
    /* border-bottom: 1px #f1f1f1 solid; */
}


.sidebar .profile-box .user-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.sidebar .profile-box .name-box .name {
    color: #222;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
}


.sidebar .profile-box .name-box .username {
    font-size: 13px;
    color: #616060;
    margin-top: -5px;
    margin-bottom: 0px;
    font-weight: 600;
}


.sidebar .box {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    margin-left: 13%;
    margin-right: 15%;
    padding-left: 5%;
    padding-right: 5%;
}

.sidebar .box:hover {
    background-color: #f1f1f1;
}

.sidebar .box .icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    color: #616060;

}

.sidebar .box .title {
    font-size: 19px;
    font-weight: 500;
}


.sidebar .box-opinion {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    margin-bottom: 5px;
    border-radius: 25px;
    margin-top: 20px;
    margin-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    margin-left: 30%;
    margin-right: 30%;
}

.sidebar .box-opinion .icon {
    width: 35px;
    color: white;
}

.sidebar .box-opinion .title {
    font-size: 18px;
    font-weight: 500;
    color: white;
}