@media only screen and (max-width: 1024px) {
  .bottom-nav {
    display: block;
  }
}


@media only screen and (min-width: 1024px) {
  .bottom-nav {
    display: none;
  }
}

.bottom-nav {
  width: 100vw;
  position: fixed;
  height: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #ccc;
}

.bottom-nav ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  background-color: #ffffff;
  padding-left: 0;
}

.bottom-nav li {
  text-align: center;
}

.bottom-nav li:last-child {
  border-right: none;
}

.bottom-nav a {
  display: block;
  padding: 8px;
  text-decoration: none;
  color: #333;
}