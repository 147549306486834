.container {
    flex: 1;
    display: flex;
    padding: 15px;
    border-top: 1px solid #f1f1f1;
    cursor: pointer;
    text-decoration:none
}


.pictureContainer {
    margin-right: 10px;
    background-color: #f1f1f1;
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.picture {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.contentContainer {
    width: 85%;
}

.contentSenderName {
    margin: 0;
    font-weight: bold;
    color: #1880a1;
}

.contentTitle {
    color: #222;
    margin: 0;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.contentMessage {
    margin: 0;
    color: #969696;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}